var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalCreateService
    },
    on: {
      "ok": _vm.createResource
    }
  }, [_c('base-service-form', {
    attrs: {
      "feedback": _vm.feedback,
      "disableTypePicker": _vm.disableTypePicker || _vm.lockType,
      "resourceTypes": _vm.resourceTypes
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }