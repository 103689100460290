<template>
  <vs-modal
    :modalInstance="modalCreateService"
    @ok="createResource"
  >
    <base-service-form
      v-model="fields"
      :feedback="feedback"
      :disableTypePicker="disableTypePicker || lockType"
      :resourceTypes="resourceTypes"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { CreateServiceError, CreateServiceSuccess } from '@/libs/toasts/feedback/module/base';
import formatDate from '@/libs/date-formatter';
import store from '@/store';
import { ref } from 'vue';
import BaseServiceForm from '../forms/BaseServiceForm.vue';

export default {
  name: 'BaseServiceCreateModal',
  emits: ['ok'],
  props: {
    disableTypePicker: {
      type: Boolean,
      default: false,
    },
    resourceTypes: {
      type: Array,
    },
  },
  setup(_, { emit }) {
    const { modalCreateService } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      setFieldDefaults,
      assignFields,
    } = useValidatedForm({
      date: 'required',
      next_date: 'date_after:@date',
      meter_status: 'required',
      next_meter_status: 'min_value:@meter_status',
      description: '',
      type: 'required',
      base_resource_id: 'required',
      base_resource_service_status_id: 'required',
      reporting_user_id: 'required',
      accepting_user_id: 'required',
    });

    setFieldDefaults({
      date: formatDate.standard(Date.now()),
      base_resource_service_status_id: 1,
      reporting_user_id: store.getters['auth/user'].id,
    });

    const lockType = ref(false);
    modalCreateService.value.on({
      open(data) {
        resetFields();
        lockType.value = !!data.lockType ?? false;
        assignFields({ ...data });
      },
    });

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateService.value.isLoading = true;

      const sendData = {
        ...fields.value,
      };

      useBaseApi()
        .createService({
          ...sendData,
        })
        .then(({ data }) => {
          showToast(CreateServiceSuccess, { name: data.data?.name });
          modalCreateService.value.close();
          emit('ok', data.data);
          modalCreateService.value.notifyObservers('success', data.data);
        })
        .catch(({ response }) => {
          showToast(CreateServiceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateService.value.isLoading = false;
        });
    };

    return {
      modalCreateService,
      fields,
      feedback,
      createResource,
      lockType,
    };
  },
  components: {
    VsModal,
    BaseServiceForm,
  },
};
</script>
