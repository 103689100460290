<template>
  <widget-card
    title="Harmonogram"
    class="base-cars-schedule-card"
    with-controls
  >

    <div class="schedule">
      <table
        v-if="currentLocation || futureLocations.length"
      >
        <thead>
          <tr>
            <th>Start</th>
            <th>Koniec</th>
            <th>Pozostało dni</th>
            <th>Inwestycja</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="schedule-entry current"
            v-if="currentLocation"
          >
            <td>{{ formatDate.training(currentLocation.resource_data.scheduler.start_date) }}</td>
            <td>{{ formatDate.training(currentLocation.resource_data.scheduler.end_date) }}</td>
            <td>
              <vs-status-badge :data="{ name: 'W toku', color: '#09cdfd' }" />
            </td>
            <td>
              <investments-investment-label :investment="currentLocation.investment" />
            </td>
          </tr>
          <tr
            v-for="location in futureLocations"
            :key="`location-${location.id}`"
            class="schedule-entry"
          >
            <td>{{ formatDate.training(location.resource_data.scheduler.start_date) }}</td>
            <td>{{ formatDate.training(location.resource_data.scheduler.end_date) }}</td>
            <td>
              <template v-if="getRemainingDays(location)">
                {{ getRemainingDays(location) }} dni
              </template>
              <vs-status-badge :data="{ name: 'W toku', color: '#09cdfd' }" v-else />
            </td>
            <td>
              <investments-investment-label :investment="location.investment" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="no-entries-info" v-else>
        Zasób nie jest przypisany do żadnej inwestycji w najbliższym czasie
      </div>
    </div>

    <template #controls>
      <vs-button
        vs-variant="light"
        @click="openFullScheduler"
      >
        Pełny harmonogram
      </vs-button>
    </template>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import InvestmentsInvestmentLabel from '@/components/views/investments/InvestmentsInvestmentLabel.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import { useRouter } from '@/@core/utils/utils';
import { computed } from 'vue';

export default {
  name: 'BaseCarsScheduleCard',
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    currentLocation: {
      type: Object,
    },
    futureLocations: {
      type: Array,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getRemainingDays = (location) => daysFromToday(location
      .resource_data.scheduler.end_date, true);

    const routeTypes = {
      car: 'cars',
      vehicle: 'machines',
      tool: 'equipment',
    };

    const resourceType = computed(() => routeTypes[props.resource?.type?.slug] ?? 'machines');

    const { router } = useRouter();
    const openFullScheduler = () => {
      router.push({ name: `base.${resourceType.value}.schedule` });
    };

    return {
      formatDate,
      getRemainingDays,
      openFullScheduler,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    VsStatusBadge,
    InvestmentsInvestmentLabel,
  },
};
</script>

<style lang="sass" scoped>
.base-cars-schedule-card

  .schedule
    overflow: auto
    padding-bottom: 10px

    table
      width: 100%

      tr
        td
          &:not(:last-child)
            padding-right: 30px
</style>
