<template>
  <vs-form
    i18nNamespace="form.base.service"
    :errors="feedback"
  >

    <vs-form-group field="type">
      <base-service-type-picker
        v-model="service.type"
        :disabled="disableTypePicker"
        :excluded="(t) => !disableTypePicker && t.slug === 'registration'"
      />

      <template #children>

        <vs-form-group labelCols="12" field="base_resource_id">
          <base-resource-picker
            v-model="service.base_resource_id"
            @pick="(resource) => selectedResource = resource"
            :excluded="shouldResourceBeExcluded"
          />
        </vs-form-group>

        <vs-form-group labelCols="12" field="base_resource_service_status_id">
          <base-service-status-picker
            v-model="service.base_resource_service_status_id"
          />
        </vs-form-group>

        <div class="child-row">
          <vs-form-group labelCols="12" cols="6" field="date">
            <vs-datepicker
              v-model="service.date"
            />
          </vs-form-group>

          <vs-form-group labelCols="12" cols="6" field="next_date">
            <vs-datepicker
              v-model="service.next_date"
            />
          </vs-form-group>
        </div>

        <div class="child-row">
          <vs-form-group labelCols="12" cols="6" field="meter_status">
            <b-input-group :append="meterStatusUnit">
              <b-form-input
                v-model="service.meter_status"
              />
            </b-input-group>
          </vs-form-group>

          <vs-form-group labelCols="12" cols="6" field="next_meter_status">
            <b-input-group :append="meterStatusUnit">
              <b-form-input
                v-model="service.next_meter_status"
              />
            </b-input-group>
          </vs-form-group>
        </div>

      </template>
    </vs-form-group>

    <vs-form-group field="reporting_user_id">
      <core-user-picker
        v-model="service.reporting_user_id"
      />
    </vs-form-group>

    <vs-form-group field="accepting_user_id">
      <core-user-picker
        v-model="service.accepting_user_id"
      />
    </vs-form-group>

    <vs-form-group field="description">
      <b-form-textarea
        v-model="service.description"
      />
    </vs-form-group>

  </vs-form>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import { computed, ref } from 'vue';
import BaseServiceTypePicker from '@/components/module/base/BaseServiceTypePicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import BaseServiceStatusPicker from '@/components/module/base/BaseServiceStatusPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import store from '@/store';

export default {
  name: 'BaseResourceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    updateStatus: {
      type: String,
    },
    disableTypePicker: {
      type: Boolean,
      default: false,
    },
    resourceTypes: {
      type: Array,
    },
  },
  setup(props) {
    store.dispatch('units/fetch');

    const service = computed(() => props.value);
    const selectedResource = ref(null);
    const meterStatusUnit = computed(() => {
      if (!selectedResource.value) return 'km';
      const unitId = selectedResource.value.category?.unit_id;
      return store.getters['units/byId'](unitId)?.code ?? 'km';
    });

    const shouldResourceBeExcluded = (resource) => {
      const hasAppropriateType = !props.resourceTypes
        || props.resourceTypes.includes(resource.type?.slug);
      if (!hasAppropriateType) return true;

      return false;
    };

    return {
      service,
      selectedResource,
      meterStatusUnit,
      shouldResourceBeExcluded,
    };
  },
  components: {
    VsForm,
    VsFormGroup,
    BaseServiceTypePicker,
    VsDatepicker,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BaseServiceStatusPicker,
    CoreUserPicker,
    BaseResourcePicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
