<template>
  <widget-card
    title="Lokalizacja"
    class="base-cars-location-card"
  >
    <template v-if="currentLocation || nextLocation">
      <div class="current-location" v-if="currentLocation">
        <h3 class="current-location-city">
          {{ currentLocation.investment.city }}
        </h3>
        <div class="current-location-investment">
          <investments-investment-label
            :investment="currentLocation.investment"
          />
        </div>
      </div>

      <div class="next-location" v-if="nextLocation">
        <div class="next-location-header">
          następna inwestycja
        </div>
        <div class="next-location-investment">
          <investments-investment-label
            :investment="nextLocation.investment"
          />
        </div>
        <div class="next-location-city">
          {{ nextLocation.investment.city }}
          ({{ formatDate.training(nextLocation.resource_data.scheduler.start_date) }})
        </div>
      </div>
    </template>

    <div class="no-entries-info" v-else>
      Zasób nie jest przypisany do żadnej inwestycji w najbliższym czasie
    </div>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import InvestmentsInvestmentLabel from '@/components/views/investments/InvestmentsInvestmentLabel.vue';
import { computed } from 'vue';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'BaseCarsLocationCard',
  props: {
    currentLocation: {
      type: Object,
    },
    futureLocations: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const nextLocation = computed(() => {
      if (!props.futureLocations.length) return null;
      return props.futureLocations[0];
    });

    return { nextLocation, formatDate };
  },
  components: {
    WidgetCard,
    InvestmentsInvestmentLabel,
  },
};
</script>

<style lang="sass" scoped>
.base-cars-location-card
  .current-location
    margin-bottom: 20px

    &-investment
      font-size: 12pt

  .next-location
    &-header
      opacity: 0.6

    &-investment
      font-size: 12pt
</style>
