<template>
  <div class="base-resource-qr-modal">
    <vs-modal
      :modalInstance="modalResourceQR"
    >
      <div class="qr-code-wrapper">
        <vue-qrcode
          class="qr-code"
          :value="qrLink"
          :options="qrOptions"
          ref="qrCodeRef"
        />

        <div class="qr-info">
          Aby zapisać kod na dysku, kliknij na niego prawym przyciskiem
          myszy i wybierz opcję "Zapisz obraz jako"
        </div>
      </div>
    </vs-modal>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { computed, ref, watch } from 'vue';

export default {
  name: 'BaseResourceQRModal',
  props: {
    resource: Object,
  },
  setup(props) {
    const { modalResourceQR } = useBaseModals();
    const qrCodeRef = ref(null);

    // temporary qr value
    const qrLink = computed(() => `budoerp://base/resource/report/${props.resource.id}/${props.resource.name}`);

    const qrOptions = {
      width: 800,
      errorCorrectionLevel: 'Q',
    };

    const drawImage = (canvas, image, x, y, width, height, radius = 2) => {
      const context = canvas.getContext('2d');

      context.shadowOffsetX = 0;
      context.shadowOffsetY = 2;
      context.shadowBlur = 4;
      context.shadowColor = '#00000040';
      context.lineWidth = 8;
      context.beginPath();
      context.moveTo(x + radius, y);
      context.arcTo(x + width, y, x + width, y + height, radius);
      context.arcTo(x + width, y + height, x, y + height, radius);
      context.arcTo(x, y + height, x, y, radius);
      context.arcTo(x, y, x + width, y, radius);
      context.closePath();
      context.strokeStyle = '#F39314';
      context.stroke();
      context.clip();
      context.fillStyle = '#fff';
      context.fillRect(x, x, width, height);
      context.drawImage(image, x + 10, x + 10, width / 1.2, height / 1.2);
    };

    const onReady = (canvas) => {
      const image = new Image();

      // eslint-disable-next-line global-require
      const logo = require('@/assets/images/logo/logo.svg');

      image.src = logo;
      image.crossorigin = 'anonymous';
      image.onload = () => {
        const coverage = 0.17;
        const width = Math.round(canvas.width * coverage);
        const x = (canvas.width - width) / 2;

        drawImage(canvas, image, x, x, width, width);
      };
    };

    watch(qrCodeRef, () => {
      if (!modalResourceQR.value.visible) return;
      if (qrCodeRef.value) {
        onReady(qrCodeRef.value.$el);
      }
    });

    return {
      modalResourceQR,
      qrOptions,
      qrLink,
      onReady,
      qrCodeRef,
    };
  },
  components: {
    VsModal,
    VueQrcode,
  },
};
</script>

<style lang="sass" scoped>
.qr-code-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  .qr-code
    width: 100% !important
    height: auto !important
    position: relative
    aspect-ratio: 1/1
    border-radius: 10px
    margin: 25px 0

  .qr-info
    margin: 25px 0
    max-width: 420px
    text-align: center
    opacity: 0.5
</style>
