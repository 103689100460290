<template>
  <widget-card
    title="Serwis"
    class="base-cars-service-card"
  >

    <template #header>

      <div
        class="header"
        v-if="service && service.next_date"
      >
        <h3 class="header-title">
          <examination-badge hide-text
            class="mb-1"
            :expired="daysTillNextInspection <= 0
              || (meterStatusTillNextInspection !== null && meterStatusTillNextInspection <= 0)"
            :about-to-expire="aboutToExpire"
          />
          Następny serwis
        </h3>
        <h4 v-if="daysTillNextInspection >= 0">
          {{ $tc('base.service.details.daysTill', daysTillNextInspection) }}
        </h4>
        <h4 v-else>
          {{ $tc('base.service.details.expired', Math.abs(daysTillNextInspection)) }}
        </h4>
        <h4 v-if="meterStatusTillNextInspection !== null">
          {{ $t('base.service.details.meterStatusLeft', {
            value: meterStatusTillNextInspection,
            unit: meterStatusUnit?.code ?? 'mth',
          }) }}
        </h4>
      </div>

      <div
        class="header"
        v-else-if="service"
      >
        <h3>Ostatni serwis</h3>
        <h4>
          {{ $tc('base.service.details.daysSince', Math.abs(daysSinceLastInspection)) }}
        </h4>
      </div>

    </template>

    <vs-loader
      full
      text="Pobieranie informacji o przeglądzie"
      style="top: 0"
      v-if="isLoading"
    />

    <template v-if="service">
      <div class="last-service">
        <div class="last-service-header">
          data ostatniego serwisu
        </div>

        <div class="last-service-date">
          <vs-button
            class="service-button"
            vs-variant="light"
            @click="openInspectionDetails"
          >
            <feather-icon class="icon" icon="LinkIcon" />
          </vs-button>
          {{ formatDate.transport(service.date) }} r.
        </div>
      </div>

      <div class="last-service mt-1" v-if="service.next_date">
        <div class="last-service-header">
          data następnego serwisu
        </div>

        <div class="last-service-date">
          {{ formatDate.transport(service.next_date) }} r.
        </div>
      </div>
    </template>

    <template v-else>
      <div class="no-service">
        {{ $t(`base.global.resourceType.${resource.type?.slug}`)}} nie ma jeszcze serwisu
      </div>
    </template>

    <template #footer>
      <div class="widget-footer">
        <vs-button
          vs-variant="light"
          @click="openCreateModal"
        >
          Dodaj serwis
        </vs-button>

        <vs-button
          vs-variant="light"
          @click="openInspectionHistory"
        >
          Historia
        </vs-button>
      </div>
    </template>

  </widget-card>
</template>

<script>
import { computed, onUnmounted, ref } from 'vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import { useRouter } from '@/@core/utils/utils';
import useBaseModals from '@/hooks/base/useBaseModals';
import store from '@/store';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';

export default {
  name: 'BaseCarsServiceCard',
  props: {
    resource: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  setup(props) {
    const service = computed(() => props.resource.newest_inspection);
    const isLoading = ref(false);

    const daysSinceLastInspection = computed(() => {
      if (!service.value) return 0;
      return daysFromToday(service.value.date, true);
    });

    const daysTillNextInspection = computed(() => {
      if (!service.value && !service.value.next_date) return 0;
      return daysFromToday(service.value.next_date, true);
    });

    const { router } = useRouter();
    const openInspectionDetails = () => {
      router.push({ name: `base.${props.type}.service.details`, params: { id: service.value.id } });
    };
    const openInspectionHistory = () => {
      router.push({ name: `base.${props.type}.service`, params: { resourceId: props.resource.id } });
    };

    const { modalCreateService } = useBaseModals();
    const openCreateModal = () => {
      modalCreateService.value.open({
        base_resource_id: props.resource.id,
        type: 'inspection',
      });
    };

    modalCreateService.value.on({
      success: () => {

      },
    }, 'BaseCarsServiceCard');

    onUnmounted(() => {
      // FIXME its dirty
      modalCreateService.value.unregisterObserver('BaseCarsServiceCard');
    });

    const meterStatusTillNextInspection = computed(() => {
      if (!service.value?.next_meter_status) return null;
      const { status } = props.resource?.meter_status ?? {};
      if (!status) return null;

      const diff = service.value.next_meter_status - (status ?? 0);
      return Math.max(0, diff);
    });

    const aboutToExpire = computed(() => {
      if (props.resource.type?.slug === 'car') {
        return daysTillNextInspection.value <= 14
          || (meterStatusTillNextInspection.value !== null
            && meterStatusTillNextInspection.value <= 1000);
      }

      return daysTillNextInspection.value <= 14
          || (meterStatusTillNextInspection.value !== null
            && meterStatusTillNextInspection.value <= 50);
    });

    const meterStatusUnit = computed(() => {
      if (!props.resource) return null;
      const unitId = props.resource.category?.unit_id;
      return store.getters['units/byId'](unitId);
    });

    return {
      isLoading,
      service,
      formatDate,
      daysSinceLastInspection,
      daysTillNextInspection,
      meterStatusTillNextInspection,
      aboutToExpire,
      meterStatusUnit,

      openInspectionDetails,
      openInspectionHistory,
      openCreateModal,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    VsLoader,
    ExaminationBadge,
  },
};
</script>

<style lang="sass" scoped>
.service-button
  padding: 4px 8px
  font-size: 9pt
  transition: .2s .2s all ease-in-out
  display: flex
  justify-content: flex-start
  overflow: hidden

.base-cars-service-card
  .header-title
    display: flex
    align-items: center
    gap: 5px

    .examination-badge
      position: absolute
      top: 20px
      right: 20px
  .last-service
    &-header
      opacity: 0.6

    &-date
      font-size: 12pt
      display: flex
      align-items: center
      gap: 10px

  .widget-footer
    display: flex
    flex-direction: column
    gap: 10px
    align-items: flex-start
</style>
