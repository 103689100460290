var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-cars-schedule-card",
    attrs: {
      "title": "Harmonogram",
      "with-controls": ""
    },
    scopedSlots: _vm._u([{
      key: "controls",
      fn: function () {
        return [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openFullScheduler
          }
        }, [_vm._v(" Pełny harmonogram ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "schedule"
  }, [_vm.currentLocation || _vm.futureLocations.length ? _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("Start")]), _c('th', [_vm._v("Koniec")]), _c('th', [_vm._v("Pozostało dni")]), _c('th', [_vm._v("Inwestycja")])])]), _c('tbody', [_vm.currentLocation ? _c('tr', {
    staticClass: "schedule-entry current"
  }, [_c('td', [_vm._v(_vm._s(_vm.formatDate.training(_vm.currentLocation.resource_data.scheduler.start_date)))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.training(_vm.currentLocation.resource_data.scheduler.end_date)))]), _c('td', [_c('vs-status-badge', {
    attrs: {
      "data": {
        name: 'W toku',
        color: '#09cdfd'
      }
    }
  })], 1), _c('td', [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.currentLocation.investment
    }
  })], 1)]) : _vm._e(), _vm._l(_vm.futureLocations, function (location) {
    return _c('tr', {
      key: `location-${location.id}`,
      staticClass: "schedule-entry"
    }, [_c('td', [_vm._v(_vm._s(_vm.formatDate.training(location.resource_data.scheduler.start_date)))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.training(location.resource_data.scheduler.end_date)))]), _c('td', [_vm.getRemainingDays(location) ? [_vm._v(" " + _vm._s(_vm.getRemainingDays(location)) + " dni ")] : _c('vs-status-badge', {
      attrs: {
        "data": {
          name: 'W toku',
          color: '#09cdfd'
        }
      }
    })], 2), _c('td', [_c('investments-investment-label', {
      attrs: {
        "investment": location.investment
      }
    })], 1)]);
  })], 2)]) : _c('div', {
    staticClass: "no-entries-info"
  }, [_vm._v(" Zasób nie jest przypisany do żadnej inwestycji w najbliższym czasie ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }