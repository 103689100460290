import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchServiceStatuses } = useBaseApi();

export default defineStore('baseServiceStatus', () => {
  const { state, fetch, fetched } = useRemoteResource(
    'base.service.status',
    fetchServiceStatuses,
  );

  return {
    state,
    fetch,
    fetched,
  };
});
