var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-resource-qr-modal"
  }, [_c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalResourceQR
    }
  }, [_c('div', {
    staticClass: "qr-code-wrapper"
  }, [_c('vue-qrcode', {
    ref: "qrCodeRef",
    staticClass: "qr-code",
    attrs: {
      "value": _vm.qrLink,
      "options": _vm.qrOptions
    }
  }), _c('div', {
    staticClass: "qr-info"
  }, [_vm._v(" Aby zapisać kod na dysku, kliknij na niego prawym przyciskiem myszy i wybierz opcję \"Zapisz obraz jako\" ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }