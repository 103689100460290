var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-cars-location-card",
    attrs: {
      "title": "Lokalizacja"
    }
  }, [_vm.currentLocation || _vm.nextLocation ? [_vm.currentLocation ? _c('div', {
    staticClass: "current-location"
  }, [_c('h3', {
    staticClass: "current-location-city"
  }, [_vm._v(" " + _vm._s(_vm.currentLocation.investment.city) + " ")]), _c('div', {
    staticClass: "current-location-investment"
  }, [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.currentLocation.investment
    }
  })], 1)]) : _vm._e(), _vm.nextLocation ? _c('div', {
    staticClass: "next-location"
  }, [_c('div', {
    staticClass: "next-location-header"
  }, [_vm._v(" następna inwestycja ")]), _c('div', {
    staticClass: "next-location-investment"
  }, [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.nextLocation.investment
    }
  })], 1), _c('div', {
    staticClass: "next-location-city"
  }, [_vm._v(" " + _vm._s(_vm.nextLocation.investment.city) + " (" + _vm._s(_vm.formatDate.training(_vm.nextLocation.resource_data.scheduler.start_date)) + ") ")])]) : _vm._e()] : _c('div', {
    staticClass: "no-entries-info"
  }, [_vm._v(" Zasób nie jest przypisany do żadnej inwestycji w najbliższym czasie ")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }