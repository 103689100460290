var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    attrs: {
      "i18nNamespace": "form.base.service",
      "errors": _vm.feedback
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "type"
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function () {
        return [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "base_resource_id"
          }
        }, [_c('base-resource-picker', {
          attrs: {
            "excluded": _vm.shouldResourceBeExcluded
          },
          on: {
            "pick": function (resource) {
              return _vm.selectedResource = resource;
            }
          },
          model: {
            value: _vm.service.base_resource_id,
            callback: function ($$v) {
              _vm.$set(_vm.service, "base_resource_id", $$v);
            },
            expression: "service.base_resource_id"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "base_resource_service_status_id"
          }
        }, [_c('base-service-status-picker', {
          model: {
            value: _vm.service.base_resource_service_status_id,
            callback: function ($$v) {
              _vm.$set(_vm.service, "base_resource_service_status_id", $$v);
            },
            expression: "service.base_resource_service_status_id"
          }
        })], 1), _c('div', {
          staticClass: "child-row"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.service.date,
            callback: function ($$v) {
              _vm.$set(_vm.service, "date", $$v);
            },
            expression: "service.date"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "next_date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.service.next_date,
            callback: function ($$v) {
              _vm.$set(_vm.service, "next_date", $$v);
            },
            expression: "service.next_date"
          }
        })], 1)], 1), _c('div', {
          staticClass: "child-row"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "meter_status"
          }
        }, [_c('b-input-group', {
          attrs: {
            "append": _vm.meterStatusUnit
          }
        }, [_c('b-form-input', {
          model: {
            value: _vm.service.meter_status,
            callback: function ($$v) {
              _vm.$set(_vm.service, "meter_status", $$v);
            },
            expression: "service.meter_status"
          }
        })], 1)], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "next_meter_status"
          }
        }, [_c('b-input-group', {
          attrs: {
            "append": _vm.meterStatusUnit
          }
        }, [_c('b-form-input', {
          model: {
            value: _vm.service.next_meter_status,
            callback: function ($$v) {
              _vm.$set(_vm.service, "next_meter_status", $$v);
            },
            expression: "service.next_meter_status"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('base-service-type-picker', {
    attrs: {
      "disabled": _vm.disableTypePicker,
      "excluded": function (t) {
        return !_vm.disableTypePicker && t.slug === 'registration';
      }
    },
    model: {
      value: _vm.service.type,
      callback: function ($$v) {
        _vm.$set(_vm.service, "type", $$v);
      },
      expression: "service.type"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "reporting_user_id"
    }
  }, [_c('core-user-picker', {
    model: {
      value: _vm.service.reporting_user_id,
      callback: function ($$v) {
        _vm.$set(_vm.service, "reporting_user_id", $$v);
      },
      expression: "service.reporting_user_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "accepting_user_id"
    }
  }, [_c('core-user-picker', {
    model: {
      value: _vm.service.accepting_user_id,
      callback: function ($$v) {
        _vm.$set(_vm.service, "accepting_user_id", $$v);
      },
      expression: "service.accepting_user_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "description"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.service.description,
      callback: function ($$v) {
        _vm.$set(_vm.service, "description", $$v);
      },
      expression: "service.description"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }