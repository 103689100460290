var render = function render(){
  var _vm$resource$type;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-cars-service-card",
    attrs: {
      "title": "Serwis"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$meterStatusUnit$c, _vm$meterStatusUnit;
        return [_vm.service && _vm.service.next_date ? _c('div', {
          staticClass: "header"
        }, [_c('h3', {
          staticClass: "header-title"
        }, [_c('examination-badge', {
          staticClass: "mb-1",
          attrs: {
            "hide-text": "",
            "expired": _vm.daysTillNextInspection <= 0 || _vm.meterStatusTillNextInspection !== null && _vm.meterStatusTillNextInspection <= 0,
            "about-to-expire": _vm.aboutToExpire
          }
        }), _vm._v(" Następny serwis ")], 1), _vm.daysTillNextInspection >= 0 ? _c('h4', [_vm._v(" " + _vm._s(_vm.$tc('base.service.details.daysTill', _vm.daysTillNextInspection)) + " ")]) : _c('h4', [_vm._v(" " + _vm._s(_vm.$tc('base.service.details.expired', Math.abs(_vm.daysTillNextInspection))) + " ")]), _vm.meterStatusTillNextInspection !== null ? _c('h4', [_vm._v(" " + _vm._s(_vm.$t('base.service.details.meterStatusLeft', {
          value: _vm.meterStatusTillNextInspection,
          unit: (_vm$meterStatusUnit$c = (_vm$meterStatusUnit = _vm.meterStatusUnit) === null || _vm$meterStatusUnit === void 0 ? void 0 : _vm$meterStatusUnit.code) !== null && _vm$meterStatusUnit$c !== void 0 ? _vm$meterStatusUnit$c : 'mth'
        })) + " ")]) : _vm._e()]) : _vm.service ? _c('div', {
          staticClass: "header"
        }, [_c('h3', [_vm._v("Ostatni serwis")]), _c('h4', [_vm._v(" " + _vm._s(_vm.$tc('base.service.details.daysSince', Math.abs(_vm.daysSinceLastInspection))) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "widget-footer"
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openCreateModal
          }
        }, [_vm._v(" Dodaj serwis ")]), _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openInspectionHistory
          }
        }, [_vm._v(" Historia ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": "",
      "text": "Pobieranie informacji o przeglądzie"
    }
  }) : _vm._e(), _vm.service ? [_c('div', {
    staticClass: "last-service"
  }, [_c('div', {
    staticClass: "last-service-header"
  }, [_vm._v(" data ostatniego serwisu ")]), _c('div', {
    staticClass: "last-service-date"
  }, [_c('vs-button', {
    staticClass: "service-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.openInspectionDetails
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "LinkIcon"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.formatDate.transport(_vm.service.date)) + " r. ")], 1)]), _vm.service.next_date ? _c('div', {
    staticClass: "last-service mt-1"
  }, [_c('div', {
    staticClass: "last-service-header"
  }, [_vm._v(" data następnego serwisu ")]), _c('div', {
    staticClass: "last-service-date"
  }, [_vm._v(" " + _vm._s(_vm.formatDate.transport(_vm.service.next_date)) + " r. ")])]) : _vm._e()] : [_c('div', {
    staticClass: "no-service"
  }, [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${(_vm$resource$type = _vm.resource.type) === null || _vm$resource$type === void 0 ? void 0 : _vm$resource$type.slug}`)) + " nie ma jeszcze serwisu ")])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }