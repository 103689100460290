import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('baseServiceType', () => {
  const state = ref([
    { name: 'Awaria', slug: 'technical' },
    { name: 'Badanie techniczne', slug: 'registration' },
    { name: 'Przegląd okresowy', slug: 'inspection' },
  ]);

  return {
    state,
  };
});
